.header {
	position: fixed;
	width: 95%;
	z-index: 5;
	background-color: #fff;
	&__card {
		&__container {
			display: flex;
			gap: 24px;
			margin-top: 10px;
			margin-bottom: 10px;
		}
	}
}
.imagebankcontainer {
	height: calc(100vh - 110px);
	overflow: auto;
	margin-top: 400px;
}
.metrics {
	padding: 24px 16px;
	border-radius: 20px;
	width: max-content;
	line-height: 1.17;
	font-family: 'Lato';
	min-width: 150px;
	padding-bottom: 26px;

	&__update {
		padding: 8px 14px;
		margin-top: 8px;
	}
	&__header {
		display: flex;
		align-items: center;
		gap: 12px;
	}
	&__icon {
		padding: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: max-content;
		border-radius: 50%;
		&__update {
			padding: 6px;
		}

		&__image {
			width: 15px;
			height: 15px;
		}
	}
	&__count {
		font-size: 32px;
		font-weight: 700;
		margin-top: 20px;

		&__update {
			font-size: 16px;
			font-weight: 400;
			margin-top: 0px;
		}
	}
	&__total {
		font-size: 15px;
	}
}
.icons {
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px;
	background: #ffffff;
	box-shadow: 0px 4px 8px rgba(49, 42, 42, 0.12);
	border-radius: 5px;
	flex-shrink: 0;
	box-sizing: border-box;
	position: relative;
}

.imagebank {
	&__metrics {
		& h1 {
			text-transform: uppercase;
		}
		&__cards {
			display: flex;
			gap: 32px;
			padding: 28px 0 20px;
		}
	}

	& select {
		& option {
			border-radius: 0;
			padding: 16px;
		}
	}
}

.imagebank__container {
	padding: 30px 42px;
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	margin-top: 250px;
	align-items: center;
	justify-content: center;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

.imagebank__image__actions {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 10px;
	padding-bottom: 10px;
	&__left,
	&__right {
		display: flex;
		align-items: center;
		gap: 8px;
	}
	&__left {
		gap: 30px;
	}
}

.imagebank__checkbox {
	display: inline-flex;
	align-items: center;
	font-size: 12px;
	line-height: 1.12;
	& input[type='checkbox'] {
		accent-color: #d9d9d9;
	}
	& select {
		border: 0;
	}
}

.imagebank__bank__actions {
	position: fixed;
	bottom: 24px;
	right: 24px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	& button {
		border: 0;
		background-color: transparent;
		border-radius: 50%;
		&:focus {
			outline: none;
		}
		&:disabled {
			opacity: 0.5;
		}
	}
	& img {
		box-shadow: 0px 5px 13px rgba(85, 169, 253, 0.7);
		border-radius: 50%;
	}
}

// Modal styles
.modal {
	padding: 36px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 20px;
	width: max-content;
	z-index: 12;

	&__carousel_button {
		background-color: rgb(212, 212, 212);
		width: max-content;
		padding: 4px 8px;
		border-radius: 6px;
		color: rgb(119, 119, 119);
		border: 0;
	}

	&__button {
		padding: 8px 24px;
		cursor: pointer;
		border: 0;
		box-shadow: 0px 4px 8px rgba(85, 168, 253, 0.2);
		border-radius: 48px;
		font-size: 12px;
		line-height: 1.12;
		font-family: 'Lato';
		color: #55a8fd;
		background-color: #fff;
	}
}

.timeline {
	position: relative;
	&__modal {
		background-color: #fff;
		position: absolute;
		z-index: 12;
		width: max-content;
		left: 0;
		bottom: 0;
		transform: translate(-16px, calc(100% + 10px));
	}
}

.backdrop {
	top: 0;
	left: 0;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 11;
}

.modal__close_button {
	display: flex;
	justify-content: flex-end;
	& img {
		width: 16px;
		height: 16px;
	}
}

.upload__form {
	display: flex;
	flex-direction: column;
	gap: 30px;
	padding: 0 24px;

	&__grid {
		display: grid;
		grid-template-columns: 120px 300px;
		row-gap: 12px;
		column-gap: 20px;
		font-size: 14px;
		align-items: center;
		& input {
			padding: 8px 16px;
		}

		&__tags {
			margin-top: 6px;
			display: flex;
			flex-wrap: wrap;
			gap: 8px;
			&__item {
				display: flex;
				align-items: center;
				gap: 8px;
				padding: 4px 8px;
				font-size: 10px;
				background-color: #d9d9d9;
				border-radius: 4px;
				width: max-content;
				text-transform: capitalize;
				color: #292929;
			}
		}
	}

	&__image {
		display: flex;
		flex-direction: column;
		gap: 24px;
		font-size: 18px;

		&__detail {
			display: flex;
			gap: 16px;
			align-items: center;
			font-size: 14px;

			&__button {
				padding: 8px 12px;
				background-color: #d9d9d9;
				color: #797979;
				border-radius: 8px;
				cursor: pointer;
			}

			& p {
				color: #55a8fd;
			}
		}
	}
	&__submit {
		width: max-content;
		padding: 12px 48px;
		border-radius: 12px;
		background-color: #55a8fd;
		color: #ffffff;
		border: 0;
		margin: 0 auto;
		font-size: 14px;
		&:focus {
			outline: none;
		}
	}
}
.upload__percentage {
	position: fixed;
	bottom: 24px;
	left: 24px;
	box-shadow: 0px 4px 8px rgba(49, 42, 42, 0.12);
	padding: 16px 32px;
	background-color: #fff;
	border-radius: 18px;
	color: #000;
	z-index: 100;

	&__bar {
		margin-top: 8px;
		background-color: #dfdfdf;
		height: 4px;
		border-radius: 4px;

		& div {
			background-color: #55a8fd;
			height: 100%;
		}
	}
}

.batch {
	display: flex;
	flex-direction: column;
	gap: 20px;

	&__details {
		color: #55a8fd;
	}

	&__deadline_input {
		& input[type='date']:after {
			content: attr(placeholder);
		}
	}

	& button {
		width: max-content;
		border: 0;
		background-color: #55a8fd;
		color: #fff;
		padding: 10px 32px;
		border-radius: 10px;
		margin: 0 auto;
	}

	&__deadline {
		display: flex;
		align-items: center;
		color: #cccbcb;
		gap: 8px;
		& input {
			border: 0;
			padding: 0;
			width: 100%;
			&:focus {
				outline: none;
			}
			&:disabled {
				background-color: transparent;
			}
		}
		& input[type='date']::before {
			color: #999999;
			content: attr(placeholder);
		}
		& input[type='date'] {
			color: #ffffff;
		}
		& input[type='date']:focus,
		& input[type='date']:valid {
			color: #666666;
		}
		& input[type='date']:focus::before,
		& input[type='date']:valid::before {
			content: '' !important;
		}
	}
}

.common_styles {
	&__input {
		border: 0;
		border-bottom: 1px solid #cccbcb;
		color: #cccbcb;
		padding: 4px 6px;
		&:focus {
			outline: none;
		}
	}
}

.imagebank__filter {
	&__heading {
		margin-bottom: 15px;
		color: #55a8fd;
		font-weight: 500;
		font-size: 24px;
	}

	z-index: 12;
	position: absolute;
	width: max-content;
	left: 0;
	transform: translate(-90%, 20px);
	&__grid {
		display: grid;
		grid-template-columns: 1fr 250px;
		gap: 10px;
	}
	padding: 16px;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0px 4px 8px rgba(49, 42, 42, 0.12);
	& select {
		border: 0;
		border-bottom: 1px solid #cccbcb;
		color: #cccbcb;
		padding: 8px;
	}
	&__buttons {
		display: flex;
		gap: 24px;
		justify-content: flex-end;
		align-items: center;
		padding-top: 24px;

		& button {
			padding: 8px 24px;
			border: 0;
			box-shadow: 0px 4px 8px rgba(85, 168, 253, 0.2);
			border-radius: 48px;
			font-size: 12px;
			line-height: 1.12;
			font-family: 'Lato';
			color: #55a8fd;
			background-color: #fff;
			// &:first-child {
			// 	background-color: #55a8fd;
			// 	color: #fff;
			// }
		}
	}
}

.toast {
	display: flex;
	gap: 16px;

	&__dismiss {
		padding-left: 16px;
		background-color: transparent;
		border: 0;
		border-left: 1px solid rgba(49, 42, 42, 0.6);
	}
}

.imagesCount {
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2px 4px;
	aspect-ratio: 1/1;
	font-size: 10px;
	color: #fff;
	background-color: rgb(255, 0, 0);
	width: max-content;
	border-radius: 50%;
	right: 0;
	transform: translate(0px, -50%);
}
