:root {
	--loader-color: #55a8fd;
	--width: 32px;
	--margin-top: 0px;
}

.loader {
	animation: spinner 1.5s linear infinite;
	width: max-content;
	margin: 0 auto;
	margin-top: var(--margin-top);
	&__spinner {
		padding: calc(var(--width) / 2);
		border: 1.5px solid var(--loader-color);
		width: max-content;
		border-radius: 50%;
		position: relative;

		&__outer {
			position: absolute;
			top: 0;
			left: 45%;
			transform: translate(-50%, -60%);
			background-color: var(--loader-color);
			width: calc(var(--width) / 2);
			height: calc(var(--width) / 2);
			border-radius: 50%;
		}
		&__inner {
			background-color: var(--loader-color);
			width: var(--width);
			height: var(--width);
			border-radius: 50%;
		}
	}
}

// @mixin keyframes() {
// 	@keyframes spinner {
// 		0% {
// 			transform: rotate(0deg);
// 		}
// 		100% {
// 			transform: rotate(360deg);
// 		}
// 	}
// }

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
