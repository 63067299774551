.rootContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
	max-width: 420px;
	padding: 12px;
	box-shadow: 1px 0px 6px #dfdfdf;
	border-radius: 10px;
}

.timelineFilter {
	margin-left: auto;
	margin-bottom: 6px;
	select {
		padding: 4px;
		border-radius: 4px;
		min-width: 80px;
		font-size: 14px;
	}
}

.timeline {
	display: grid;
	grid-template-columns: 80px minmax(250px, 1fr);
	gap: 8px;
}

.timelineDate {
	text-align: right;
	color: #a6a5a5;
	font-size: 14px;
	padding-top: 4px;
	width: max-content;
}

.card {
	padding: 12px 8px;
	border-radius: 6px;
	flex: 1;
}

.closed {
	display: flex;
	justify-content: space-between;
	align-items: center;
	.primaryDetails {
		font-size: 14px;
		span {
			font-size: 12px;
		}
	}
}

.primaryDetails {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	font-size: 16px;

	p {
		font-weight: 600;
		margin: 0;
		margin-bottom: 4px;
		span {
			font-weight: 400;
			font-size: 14px;
		}
	}

	.date {
		font-size: 12px;
		font-weight: 500;
	}
}

.usersInfo {
	display: flex;
	align-items: center;
	gap: 8px;

	.creatorImg {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		img {
			border-radius: 50%;
			object-fit: cover;
		}
	}
	span {
		font-size: 14px;
	}
}
