.metrics {
	padding: 24px 16px;
	border-radius: 20px;
	width: max-content;
	line-height: 1.17;
	font-family: 'Lato';
	min-width: 150px;
	padding-bottom: 26px;

	&__icon {
		padding: 8px;
		width: max-content;
		border-radius: 50%;
	}
	&__count {
		font-size: 32px;
		font-weight: 700;
		margin-top: 16px;
	}
	&__total {
		font-size: 15px;
		margin-top: 8px;
	}

	&__more {
		color: #55a8fd;
		font-size: 12px;
		margin-top: 24px;
	}
}
