.rootContainer {
	height: calc(100vh - 85px);
	padding: 0 30px;
	margin-bottom: 0;
	overflow: auto;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;

	&__breadcrumps {
		margin: 16px auto;
		color: #797979;
		display: flex;
		gap: 10px;
		align-items: center;

		> img {
			cursor: pointer;
		}

		> p {
			word-spacing: 0.1em;
		}
	}

	&__paybutton {
		font-size: 14px;
		color: #55a8fd;
		border: 1px solid #55a8fd;
		border-radius: 10px;
		padding: 4px 20px;
		background-color: white;
		box-shadow: 0px 3px 6px 0px #55a8fd80;
		margin: 4px 4px;
		cursor: pointer;

		&__disabled {
			color: grey;
			border: 1px solid grey;
			box-shadow: 0px 3px 6px 0px grey;
		}
	}
}

.tabs {
	&__container {
		overflow-x: auto;
		height: calc(100vh - 270px);
	}

	&__list {
		width: max-content;
		padding-top: 20px;
		&__item {
			padding: 8px 16px;
			border: 0;
			background-color: transparent;
			font-size: 14px;
			&:not(:last-child) {
				margin-right: 4px;
			}
			&__selected {
				font-size: 16px;
				border-bottom: 2.5px solid #55a8fd;
				color: #55a8fd;
				&:focus {
					outline: none;
				}
			}
		}
		&__container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			&__right {
				display: flex;
				align-items: center;
				gap: 8px;
			}

			&__buttons {
				padding: 10px 16px;
				border: 0;
				background-color: #55a8fd;
				color: #fff;
				border-radius: 8px;
			}
		}
	}
}

.billing__amount {
	color: #55a8fd;
	text-decoration: underline;
	font-weight: 500;
	cursor: pointer;
}

.icons {
	width: 32px;
	height: 32px;
	padding: 6px;
	background: #ffffff;
	box-shadow: 0px 4px 8px rgba(49, 42, 42, 0.12);
	border-radius: 5px;
	flex-shrink: 0;
	box-sizing: border-box;
	position: relative;
}

.filters {
	position: absolute;
	min-height: 100px;
	min-width: 100px;
	top: 0;
	right: 0;
	transform: translate(0, 35px);
	background-color: #fff;
	z-index: 12;
	padding: 32px 48px;
	border-radius: 8px;
	box-shadow: 0px 4px 8px rgba(49, 42, 42, 0.12);
	&__heading {
		font-size: 16px;
		font-weight: 600;
		color: #000000;
		margin-bottom: 16px;
	}
	&__grid {
		&__container {
			display: flex;
			flex-direction: column;
			gap: 16px;
			&__buttons {
				margin-top: 24px;
				display: flex;
				justify-content: flex-end;
				gap: 16px;
				&__button {
					padding: 10px 16px;
					border: 0;
					background-color: #55a8fd;
					color: #fff;
					border-radius: 8px;
				}
			}
		}

		&__elements {
			display: grid;
			grid-template-columns: 1fr 200px;
			grid-gap: 16px;
		}
	}
}

.modal {
	padding: 64px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 20px;
	width: max-content;
	z-index: 12;

	&__header {
		text-align: center;
		margin-bottom: 24px;
		color: #55a8fd;
		font-size: 24px;
		line-height: 1.12;
	}

	&__body {
		display: flex;
		flex-direction: column;
		align-items: center;
		&__grid {
			display: grid;
			grid-template-columns: 200px 1fr;
			gap: 16px;
			& input {
				background-color: transparent;
				width: 100%;
				border: 0;
				color: #000;
				padding: 4px 6px;
				box-sizing: border-box;
				background-color: transparent;
				border-bottom: 1px solid #000;
				&:focus {
					outline: none;
				}
			}
		}
	}

	&__close_button {
		display: flex;
		justify-content: flex-end;
		& img {
			width: 16px;
			height: 16px;
		}
	}

	&__buttons {
		margin-top: 48px;
		display: flex;
		justify-content: center;
		gap: 16px;
		& button {
			padding: 10px 16px;
			border: 0;
			background-color: #55a8fd;
			color: #fff;
			border-radius: 8px;
			cursor: pointer;
		}
		&__disabled {
			& button {
				background-color: #d8d8d8;
				color: #fff;
			}
		}
	}
}

.backdrop {
	top: 0;
	left: 0;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 11;
	&__transparent {
		background-color: transparent;
		z-index: 1;
	}
}

.billpaymodal {
	&__buttons {
		display: flex;
		width: 100px;
		gap: 16px;
		margin-top: 48px;
		margin-left: auto;
		margin-right: 50px;
		& button {
			padding: 10px 16px;
			border: 0;
			background-color: #55a8fd;
			color: #fff;
			border-radius: 8px;
			cursor: pointer;
		}
	}
}

.pricing__form {
	&__request__name {
		font-size: 20px;
	}
	&__optioncontainer {
		display: flex;
		justify-content: center;
		margin-top: 24px;
	}
	&__heading {
		text-align: center;
		margin-top: 36px;
		margin-bottom: 24px;
		font-size: 24px;
		line-height: 1.12;
	}
	&__container {
		display: flex;
		flex-direction: column;

		align-items: center;

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 95%;
			margin-bottom: 24px;

			&__right {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				gap: 16px;
			}

			&__left {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 12px;
			}
		}
	}
	&__submitcontainer {
		display: flex;
		justify-content: end;
		margin-top: 24px;

		&__button {
			padding: 10px 16px;
			border: 0;
			background-color: #55a8fd;
			color: #fff;
			border-radius: 8px;
		}
	}
	&__annotation {
		display: flex;
		justify-content: center;
		border: 1px solid #aeaeae;
		&__grid {
			min-width: 500px;
			display: grid;
			grid-template-columns: max-content 300px;
			row-gap: 20px;
			column-gap: 20px;
			font-size: 14px;
			align-items: center;
			margin-top: 40px;
			margin-bottom: 40px;
			& input {
				padding: 8px 16px;
			}

			& input,
			select {
				border: 0;
				border-bottom: 1px solid #aeaeae;
			}
		}
	}
	&__class {
		display: flex;
		justify-content: center;
		border: 1px solid #aeaeae;
		&__grid {
			// height: calc(400px);
			// overflow-y: scroll;

			margin-top: 40px;
			margin-bottom: 50px;
			&__container {
				height: calc(600px);
				overflow-y: scroll;
			}
			&__row {
				min-width: 500px;
				display: grid;
				grid-template-columns: 150px 150px 150px 150px 150px 150px 150px 150px;
				row-gap: 20px;
				column-gap: 20px;
				font-size: 14px;
				align-items: center;
				margin-top: 40px;
				margin-bottom: 40px;
				& input {
					padding: 8px 16px;
					background-color: white;
				}

				& input,
				select {
					border: 0;
					border-bottom: 1px solid #aeaeae;
				}
			}
			&__header {
				position: sticky;
				display: grid;
				grid-template-columns: 150px 150px 150px 150px 150px 150px 150px 150px;
				row-gap: 20px;
				column-gap: 20px;
				top: 0;
			}
		}
	}
}

.generate__bill__form {
	&__modal {
		position: fixed;
		bottom: 50%;
		left: 50%;
		background-color: #fff;
		padding: 32px;
		z-index: 12;
		transform: translate(-50%, 50%);
		width: 500px;
		display: flex;
		flex-direction: column;
		align-items: center;

		&__heading {
			color: #55a8fd;
			font-size: 24px;
			text-align: center;
			margin-bottom: 8px;
		}

		&__requestname {
			font-size: 16px;
			margin-bottom: 24px;
		}
	}
	&__grid {
		display: grid;
		grid-template-columns: max-content 300px;
		row-gap: 12px;
		column-gap: 20px;
		font-size: 14px;
		align-items: center;
		& input {
			padding: 8px 16px;
		}

		& input,
		select {
			border: 0;
			border-bottom: 1px solid #aeaeae;
		}

		& select {
			padding: 8px;
		}
	}
}

.detailview {
	display: flex;
	gap: 12px;
	margin-top: 80px;
	font-size: 20px;
	&__heading {
		display: flex;
		gap: 48px;
		justify-content: space-between;

		&__green {
			color: #48985f;
		}

		&__red {
			color: #f05e7c;
		}

		&__left {
			display: flex;
			gap: 12px;
			align-items: center;
		}

		&__right {
			display: flex;
			gap: 32px;
			align-items: center;
		}
	}

	&__totalstring {
		display: flex;
		gap: 12px;
	}
}

.button {
	padding: 10px 16px;
	border: 0;
	background-color: #55a8fd;
	color: #fff;
	border-radius: 8px;
}
