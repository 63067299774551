.annotation {
	padding: 16px;
	display: flex;
	height: calc(100vh - 98px);
	box-sizing: border-box;
	margin: 2px;
	padding-bottom: 8px;
	justify-content: space-between;
	gap: 16px;
	position: relative;
	&__tool {
		width: 65%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		gap: 12px;
		&__icons {
			display: flex;
			& > * {
				display: flex;
				align-items: center;
				gap: 20px;
				& > * {
					padding: 4px;
				}
				&:first-child {
					padding-right: 16px;
					border-right: 1px solid #d5d5d5;
				}
				&:last-child {
					padding-left: 12px;
				}
			}

			&__selected {
				background-color: rgb(1, 112, 28);
			}

			&__disabled {
				opacity: 0.1;
			}

			img {
				width: 20px;
				height: 20px;
			}
		}

		&__image_previewer {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
			& > div {
				width: 100%;
				height: 100%;
			}
		}
	}

	&__details {
		flex: 1;
		height: 100%;
		position: relative;
		&_container {
			position: sticky;
			background-color: #fff;
			top: 0;
			z-index: 1;
			& > * {
				margin-bottom: 12px;
			}
		}
		& button {
			border: 0;
			&:focus {
				outline: none;
			}
		}
		&__current {
			display: flex;
			width: 100%;
			box-sizing: border-box;
			background-color: #f5f5f5;
			padding: 12px 24px;
			border-radius: 6px;
			justify-content: space-between;
			align-items: center;

			font-size: 12px;
			font-family: 'Lato', sans-serif;
			line-height: 1.2;

			&__actions {
				width: 12px;
				height: 12px;
				border-radius: 50%;
				position: relative;
				flex-shrink: 0;
				display: flex;
				align-items: center;
			}

			&__accordion {
				padding: 12px;
				background-color: #f5f5f5;

				&__button {
					margin-left: auto;
					padding: 4px 14px;
					border-radius: 11.5px;
					width: max-content;
					color: #55a8fd;
					border: 1px solid #55a8fd !important;
					background-color: #fff;
					display: block;
					margin-top: 8px;
					&:focus {
						outline: none;
					}
				}
			}

			&__icons {
				display: flex;
				gap: 8px;

				&__rotate {
					transform: rotate(180deg);
				}
			}
		}

		&__annotation_container {
			background-color: #f5f5f5;
			border-radius: 6px;
			padding: 12px;
			display: flex;
			flex-direction: column;
			flex: 1;
			overflow-y: scroll;
			height: calc(100vh - 190px);
			padding-bottom: 0;
			gap: 6px;
		}
	}
}

.request__info {
	position: relative;

	&__label {
		display: flex;
		align-items: center;
		gap: 5px;
	}

	&__modal {
		position: absolute;
		top: 30px;
		left: 16px;
		background-color: #fff;
		box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
		width: max-content;
		min-width: 400px;
		max-width: 800px;
		padding: 10px 20px;
		z-index: 12;

		&__refbutton {
			margin-left: auto;
			padding: 4px 14px;
			border-radius: 11.5px;
			width: max-content;
			color: #55a8fd;
			border: 1px solid #55a8fd !important;
			background-color: #fff;
			display: block;
			margin-top: 16px;
			&:focus {
				outline: none;
			}
		}
	}
}

.reference__image__modal {
	position: fixed;
	bottom: 50%;
	left: 50%;
	padding: 32px;
	z-index: 12;
	transform: translate(-50%);
}

.pagination {
	height: 90%;
	margin: auto;
	display: flex;
	flex-direction: column;

	&__container {
		display: flex;
	}

	&__icon {
		display: flex;
		align-items: center;
		position: relative;
		& > div {
			height: 22px;
		}
		& > span {
			position: absolute;
			line-height: 1.2;
			font-size: 10px;
			right: 0;
			background-color: #ebeaea;
			padding: 4px;
			border-radius: 50%;
		}
	}
	&__icon__left {
		transform: rotate(90deg);
		width: max-content;
		margin: auto;
	}
	&__icon__right {
		margin: auto;
		width: max-content;
	}
	&__images {
		display: flex;
		height: 100%;
		overflow: hidden;
		flex-direction: column;
		gap: 12px;
		& > div {
			border-radius: 10px;
			margin: auto;
			background-size: cover;
			background-repeat: no-repeat;
			aspect-ratio: 1 / 1;
		}
	}
}

.complete__session {
	position: sticky;
	padding: 5px;
	background-color: #f5f5f5;
	bottom: 0;
	display: flex;
	align-items: center;
	gap: 20px;
	&__button {
		padding: 4px 14px;
		border-radius: 11.5px;
		width: max-content;
		color: #fff;
		border: 1px solid #55a8fd !important;
		background-color: #55a8fd;
		margin-left: 8px;
		cursor: pointer;

		&:focus {
			outline: none;
		}
	}
}

.info__button {
	position: absolute;
	bottom: 10px;
	right: 10px;
	background-color: #f5f5f5;
}

.actions__modal {
	position: absolute;
	z-index: 2;
	width: 180px;
	background: #ffffff;
	box-shadow: 0px 1px 3px rgba(85, 79, 79, 0.25);
	border-radius: 4px;
	// transform: translate(-12px, 16px);
	right: 0;

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		li {
			padding: 10px;
			cursor: pointer;
			font-size: 16px;
			border-bottom: 1px solid #d9d9d9;
			&:hover {
				background-color: #f5f5f5;
			}
		}
	}
}

.backdrop {
	top: 0;
	left: 0;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 11;
	&__transparent {
		background-color: transparent;
		z-index: 1;
	}
}

.short__cut__modal {
	z-index: 15;
	background-color: #ffffff;
	position: absolute;
	width: max-content;
	right: 25px;
	bottom: 25px;
	border: 1px solid black;
}
