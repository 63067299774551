.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.45);
	z-index: 11;
}
.carousel {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 32px;
	background-color: #fff;
	border-radius: 20px;
	z-index: 12;

	&__buttons {
		margin: auto;
		width: 30px;
		height: 30px;
		cursor: pointer;
	}

	&__heading {
		display: flex;
		justify-content: space-between;
		padding: 0 16px 16px;
		& p {
			& span {
				color: #55a8fd;
			}
		}

		&__icons {
			display: flex;
			align-items: center;
			gap: 8px;

			& div {
				width: 25px;
				height: 18px;
				display: flex;
				align-items: center;
				box-shadow: 0px 2.93333px 5.86667px rgba(49, 42, 42, 0.12);
				border-radius: 8px;
				padding: 6px 8px;
			}
		}
	}

	&__images {
		display: flex;
		max-width: 900px;
		overflow-x: auto;
		overflow-y: hidden;
		flex-wrap: nowrap;
		gap: 16px;
		&::-webkit-scrollbar {
			display: none;
		}

		&__guidelines {
			margin-top: 20px;

			&__heading {
				margin-bottom: 10px;
			}

			&__content {
				max-height: 150px;
				overflow: scroll;
				display: flex;
				flex-direction: column;
				gap: 5px;
			}
		}

		&__imageContainer {
			flex-shrink: 0;
			width: 900px;
			height: 501px;
			display: flex;
			justify-content: center;
			align-items: center;
			object-fit: contain;
			border-radius: 16px;
			& img {
				border-radius: 16px;
			}
		}

		&__details {
			display: flex;
			justify-content: space-between;
			padding: 16px;

			& button {
				border: 0;
				background-color: transparent;
				text-decoration: underline;
				color: #55a8fd;
				&:focus {
					outline: none;
				}
			}

			&__container {
				display: flex;
				flex-direction: column;
				font-family: 'Lato';
				gap: 8px;
				text-transform: capitalize;
				padding: 15px;
				&__assign {
					display: flex;
					justify-content: space-between;
					align-items: center;

					& p:first-child {
						padding: 4px 12px;
						background-color: #b5e9c4;
						color: #48985f;
						border-radius: 5px;
					}
				}

				&__users {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				&__tags {
					display: flex;
					align-items: center;
					gap: 8px;
				}
			}
		}
	}
}
