@import '../../../scss/imports';

.request__actions {
	display: flex;
	align-items: center;
	justify-content: space-between;
	&__left,
	&__right {
		display: flex;
		align-items: center;
		gap: 8px;
	}
	&__left {
		gap: 30px;
	}

	&__modal {
		position: absolute;
		list-style: none;
		background-color: #fff;
		z-index: 13;
		right: 0;
		text-align: left;
		box-shadow: 0px 1px 3px rgba(85, 79, 79, 0.25);
		border-radius: 4px;
		padding: 8px;
		& li {
			padding: 8px 16px;
			color: #55a8fd;
			text-decoration: underline;
			cursor: pointer;
			& a {
				text-decoration: none;
				color: #55a8fd;
			}
		}
	}
}

.split__request {
	&__modal {
		position: fixed;
		top: 50%;
		left: 50%;
		background-color: #fff;
		padding: 32px;
		z-index: 12;
		transform: translate(-50%, -50%);
	}

	&__form {
		z-index: 15;
	}
}

.request__flow__modal {
	max-width: 400px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	text-align: justify;
	white-space: pre-line;
}

.icons {
	width: 32px;
	height: 32px;
	padding: 6px;
	background: #ffffff;
	box-shadow: 0px 4px 8px rgba(49, 42, 42, 0.12);
	border-radius: 5px;
	flex-shrink: 0;
	box-sizing: border-box;
}

.request__searchbar {
	display: inline-flex;
	align-items: center;
	padding: 8px 16px;
	border-radius: 6px;
	width: 300px;
	gap: 8px;
	border: 1px solid rgba(0, 0, 0, 0.12);
	& img {
		width: 12px;
		height: 12px;
	}
	& input {
		border: 0;
		width: 100%;
		font-size: 14px;
		line-height: 1.12;
		&:focus {
			outline: none;
		}
	}
}

.request {
	overflow: auto;
	height: calc(100vh - 155px);

	&__details {
		background-color: #f8f8f8;
		width: 90%;
		padding: 42px 56px;
		box-sizing: border-box;
		border-radius: 12px;

		@media screen and (max-width: 1024px) {
			padding: 24px;
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 8px;
			&__title {
				font-family: 'Lato';
				&__label {
					font-size: 32px;
					line-height: 1.17;
					font-weight: 400;
					color: $color-primary;
					margin-bottom: 8px;
					@media screen and (max-width: 1024px) {
						font-size: 24px;
					}
				}
				&__time {
					color: $color-subtitle;
					font-size: 14px;
					line-height: 1.14;
					font-weight: 400;
					@media screen and (max-width: 1024px) {
						font-size: 12px;
					}
				}
			}

			&__status {
				display: flex;
				align-items: center;
				gap: 8px;
				color: #fff;
				font-size: 14px;
				line-height: 1.14;
				font-weight: 400;
				font-family: 'Lato';
				text-transform: capitalize;

				@media screen and (max-width: 1024px) {
					font-size: 10px;
				}

				&__action {
					padding: 4px 16px;
					background-color: #55a8fd;
					border-radius: 4px;
				}
				&__qa {
					background-color: #48985f;
					padding: 4px 16px;
					border-radius: 4px;
				}
			}
		}

		&__description {
			&__items {
				margin-top: 12px;
				margin-bottom: 24px;
				display: flex;
				flex-direction: row;
				gap: 12px 8px;
				flex-wrap: wrap;
				&__item {
					padding: 3px 4px;
					border: 0.5px solid #55a8fd;
					background-color: rgba(200, 227, 255, 0.2);
					width: max-content;
					border-radius: 4px;
					color: $color-secondary;
					font-size: 11px;
					text-transform: capitalize;
				}
			}
			&__descr {
				& p {
					padding: 4px;
					color: $color-subtitle;
				}
			}
		}
	}

	&__table__header {
		position: sticky;
		top: 0px;
		// z-index: 5;
		font-size: 13px;
		border-radius: 40px;

		&__action {
			position: sticky !important;
			right: 0;
			background-color: #f8f8f8;
		}
	}

	&__row {
		position: relative;
		z-index: 1;
		&_selected {
			background: rgba(218, 236, 255, 0.2) !important;
		}
	}

	&__table__cell {
		color: $color-primary;
		font-size: 13px;
		& sub {
			color: $color-secondary;
		}

		&__action {
			right: 0;
			background-color: #fff;

			& img {
				margin-right: 8px;
			}
		}

		&__status {
			padding: 4px 8px;
			border: 0.5px solid #55a8fd;
			color: #55a8fd;
			background-color: rgba(200, 227, 255, 0.2);
			width: max-content;
			border-radius: 4px;

			&__ratio {
				color: #2196f3;
				margin: auto;
			}

			&__completed {
				background-color: rgba(72, 152, 95, 0.1);
				border: 0.5px solid #48985f;
				color: #48985f;
			}

			&__hold {
				background-color: rgba(246, 142, 13, 0.1);
				border: 0.5px solid #f68e0d;
				color: #f68e0d;
			}

			&__failed {
				background-color: rgba(248, 100, 100, 0.1);
				border: 0.5px solid #f86464;
				color: #f86464;
			}

			&__draft {
				background-color: rgba(190, 132, 254, 0.1);
				border: 0.5px solid #be84fe;
				color: #be84fe;
			}
		}

		&__user {
			display: flex;
			gap: 4px;
			align-items: center;
			& img {
				width: 22px;
				height: 22px;
				border-radius: 50%;
			}
		}
	}

	&__carousel_elements {
		cursor: pointer;
		text-decoration: underline;
	}

	&__carousel_alignment {
		text-align: right;
	}

	&__metrics {
		margin-bottom: 32px;
		display: flex;
		justify-content: space-between;
		&__requests {
			flex-shrink: 0;
			&_list {
				display: flex;
				gap: 16px;
				margin-top: 24px;
			}
		}
	}

	&__timeline {
		position: relative;
		&__modal {
			background-color: #fff;
			position: absolute;
			z-index: 12;
			width: max-content;
			left: 0;
			bottom: 0;
			transform: translate(-16px, calc(100% + 10px));
		}
	}

	&__raise_button {
		position: fixed;
		bottom: 16px;
		right: 16px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		& > button {
			background-color: transparent;
			border: 0;
			& > img {
				border-radius: 50%;
				box-shadow: 0px 5px 13px rgba(85, 169, 253, 0.7);
			}
		}
	}

	&__annotation__button {
		border: 0;
		background-color: #f5f5f5;
		padding: 16px;
		border-radius: 8px;
		margin-top: 16px;
	}
}

.modal {
	padding: 36px 48px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 20px;
	width: max-content;
	min-width: 500px;
	z-index: 12;

	&__close_button {
		display: flex;
		justify-content: flex-end;
		& img {
			width: 16px;
			height: 16px;
		}
	}

	&__buttons {
		margin-top: 24px;
		display: flex;
		justify-content: flex-end;
		gap: 16px;
		& button {
			padding: 10px 16px;
			border: 0;
			background-color: #55a8fd;
			color: #fff;
			border-radius: 8px;
			cursor: pointer;
			&:disabled {
				cursor: not-allowed;
				background-color: #e5e5e5;
				color: #000;
			}
		}
	}
}
.asterisk {
	color: red;
}
.table__header__styles {
	background-color: white;
	border-bottom: 1px solid #d2d2d2;
}
.billing__actions {
	display: flex;
	justify-content: end;
	align-items: center;
	gap: 16px;

	&__button {
		padding: 10px 16px;
		margin-bottom: 12px;
		margin-top: -24px;
		border: 0;
		background-color: #55a8fd;
		color: #fff;
		border-radius: 8px;
		text-decoration: none;
		cursor: pointer;
	}
}
.backdrop {
	top: 0%;
	left: 0%;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 11;
	&__transparent {
		background-color: transparent;
		z-index: 1;
	}
}

.upload__form {
	&__guidelines {
		&__heading {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 22px;

			> img {
				cursor: pointer;
				width: 35px;
			}
		}
	}
	&__grid {
		display: grid;
		grid-template-columns: max-content 300px;
		row-gap: 12px;
		column-gap: 20px;
		font-size: 14px;
		align-items: center;
		& input {
			padding: 8px 16px;
		}

		& input,
		select {
			border: 0;
			border-bottom: 1px solid #aeaeae;
		}

		& select {
			padding: 8px;
		}
	}
}

.class {
	&__attributes {
		display: flex;
		flex-direction: column;
		min-height: 250px;
		gap: 24px;
		&__options {
			// position: absolute;
			// transform: translateY(100%);
			background-color: #fff;
			// bottom: 0;
			// left: 0;
			max-height: 150px;
			overflow: auto;
			list-style: none;
			box-shadow: 1px 1px 4px rgb(0 0 0 / 12%);
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;

			&__option {
				border-bottom: 1px solid rgba(0, 0, 0, 0.12);
				padding: 12px 16px;
			}
		}
		&__buttons {
			display: flex;
			gap: 8px;
			& button {
				cursor: pointer;
				padding: 10px 16px;
				border: 0;
				background-color: #55a8fd;
				color: #fff;
				border-radius: 8px;
				width: max-content;
				&.cancel {
					background-color: rgba(121, 121, 121, 0.45);
				}

				&:disabled {
					background-color: rgba(121, 121, 121, 0.45);
				}
			}
		}

		&__type {
			display: flex;
			gap: 16px;
			& > div {
				display: flex;
				align-items: center;
				gap: 8px;
			}
		}
	}
}

.options_open {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}

.options_closed {
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}

.tabs {
	&__list {
		margin-bottom: 24px;
		margin-top: 12px;
		// margin: auto 0;
		width: max-content;
		display: flex;
		align-items: center;
		gap: 12px;
		&__item {
			padding: 8px 16px;
			border: 0;
			background-color: white;
			font-size: 14px;
			&:not(:last-child) {
				margin-right: 4px;
			}
			&__selected {
				font-size: 16px;
				border-bottom: 2.5px solid #55a8fd;
				color: #55a8fd;
				&:focus {
					outline: none;
				}
			}

			&__hidden {
				display: none;
			}
		}
	}
}

.type_style {
	border-right: 1px solid #ababab;
	width: 25%;
	text-align: center;
}

.lastTypeStyle {
	width: 25%;
	text-align: center;
}

.class__proportion {
	&__modal {
		position: fixed;
		bottom: 0%;
		left: 50%;
		background-color: #fff;
		padding: 32px;
		z-index: 12;
		transform: translate(-50%);
		&__container {
			display: flex;
			width: 100vw;
			justify-content: space-around;
		}

		&__piecontainer {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 16px;
		}

		&__labelscontainer {
			display: flex;
			max-width: 300px;
			flex-wrap: wrap;
			gap: 16px;
			justify-content: center;
			align-content: flex-start;
			height: 120px;
			overflow-y: scroll;
		}

		&__color {
			width: 20px;
			height: 20px;
		}

		&__labelcontainer {
			display: flex;
			gap: 4px;
			cursor: pointer;
		}

		&__selectedLabel {
			color: #2196f3;
		}

		&__heading {
			margin-bottom: 20px;
			font-size: 20px;
		}

		&__mainheading {
			font-size: 24px;
			margin-bottom: 44px;
			text-align: center;
		}

		&__options {
			display: flex;
			justify-content: space-between;
			padding-left: 200px;
			padding-right: 200px;
			margin-bottom: 80px;
		}

		&__export {
			background-color: #55a8fd;
			border-radius: 6px;
			color: white;
			padding: 10px 24px;
			border: none;
		}
	}
	&__backdrop {
		top: 0;
		left: 0;
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 11;
	}
}

.remove {
	&__user {
		&_button {
			font-weight: 500;
			color: #55a8fd;
			border: 0;
			background-color: transparent;
			&:focus {
				outline: none;
			}
		}
	}
}

.classes {
	&__tags {
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		margin-top: 8px;
		&__item {
			display: flex;
			align-items: center;
			gap: 8px;
			padding: 4px 8px;
			font-size: 10px;
			background-color: #d9d9d9;
			border-radius: 4px;
			width: max-content;
			text-transform: capitalize;
			color: #292929;
		}
	}
}

.upload__form {
	&__image {
		display: flex;
		flex-direction: column;
		gap: 8px;
		margin-top: 24px;

		&__detail {
			display: flex;
			gap: 16px;
			align-items: center;
			font-size: 14px;

			&__button {
				padding: 8px 12px;
				background-color: #d9d9d9;
				color: #797979;
				border-radius: 8px;
				cursor: pointer;
			}

			& p {
				color: #55a8fd;
			}
		}
	}
}

.container {
	margin-top: 16px;
}

.highlight {
	border: 1px solid #55a8fd;
	color: #55a8fd;
	border-radius: 8px;
}
.root__container {
	height: calc(100vh - 80px);
	padding: 0;
	margin-bottom: 0;
	overflow: auto;

	&__header {
		position: sticky;
		top: 0;
		background-color: #fff;
		z-index: 1;
		border-radius: 12px 12px 0 0;
		padding-right: 30px;

		&__actions {
			margin-left: auto;
			justify-content: flex-end;
			align-items: center;
			display: flex;
			height: 60px;
			gap: 32px;
			position: relative;

			& img {
				cursor: pointer;
			}

			&__iconcontainer {
				width: 30px;
				height: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
				border-radius: 5px;
			}
		}
	}
}

.requests__filter {
	z-index: 13 !important;
	position: absolute;
	width: max-content;
	right: 0;
	top: 50px;
	display: flex;
	flex-direction: column;

	&__heading {
		margin-bottom: 15px;
		color: #55a8fd;
		font-weight: 500;
		font-size: 24px;
	}

	&__grid {
		display: grid;
		grid-template-columns: 1fr 250px;
		gap: 15px;
	}
	padding: 16px;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0px 4px 8px rgba(49, 42, 42, 0.12);

	& select {
		border: 0;
		border-bottom: 1px solid #cccbcb;
		color: #cccbcb;
		padding: 8px;
	}
	& input {
		border: 0;
		border-bottom: 1px solid #cccbcb;
		color: #cccbcb;
		padding: 8px;
	}
	&__buttons {
		display: flex;
		gap: 24px;
		justify-content: flex-end;
		align-items: center;
		padding-top: 24px;

		& button {
			padding: 8px 24px;
			cursor: pointer;
			border: 0;
			box-shadow: 0px 4px 8px rgba(85, 168, 253, 0.2);
			border-radius: 8px;
			font-size: 12px;
			line-height: 1.12;
			font-family: 'Lato';
			color: #55a8fd;
			background-color: #fff;
			&:first-child {
				background-color: #55a8fd;
				color: #fff;
			}
		}
	}
}

.colour {
	&__blue {
		color: blue;
	}
	&__green {
		color: green;
	}
	&__orange {
		color: orange;
	}
	&__red {
		color: red;
	}
	&__grey {
		color: grey;
	}
}

.hide {
	display: none;
}

.assigned__user__filter {
	position: relative;
	display: flex;
}

.switch {
	position: relative;
	display: inline-block;
	width: 55px;
	height: 28px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: '';
	height: 20px;
	width: 20px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #2196f3;
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

// .fix_actions {
// 	position: sticky;
// 	left: 0;
// }
// .fix_labels {
// 	position: sticky;
// 	left: 44px;
// }

.progress {
	&__container {
		background-color: white;

		&__header {
			background-color: white;
			border-bottom: 1px solid #d2d2d2;

			&__split {
				&__heading {
					text-align: center;
				}
				&__content {
					margin-left: 30px;
					display: flex;
					gap: 20px;
					flex: 1;
					> p {
						width: 70px;
						text-align: center;
					}
				}
			}
		}
	}
}

.loader {
	position: fixed;
	bottom: 48px;
	left: 24px;
	box-shadow: 0px 4px 8px rgba(49, 42, 42, 0.12);
	padding: 16px 32px;
	background-color: #fff;
	border-radius: 18px;
	color: #000;
	z-index: 100;
}
