.user {
	display: flex;
	gap: 16px;
	align-items: center;
	&__avatar {
		width: 32px;
		height: 32px;
		& img {
			border-radius: 50%;
		}
	}

	&__status {
		padding: 6px 16px;
		width: max-content;
		border-radius: 20px;
		font-size: 12px;
		box-shadow: 1px 2px 4px #dfdfdf;
		// background-color: #ababab63;
		color: rgb(107, 107, 107);
		&__active {
			color: #55a8fd;
			box-shadow: none;
			border: 1px solid #55a8fd;
			border-radius: 11px;
		}
		&__unverified {
			color: #f05e7c;
			border: 1px solid #f05e7c;
			box-shadow: none;
		}
	}
}

.tabs {
	&__list {
		margin-bottom: 24px;
		// border-bottom: 1px solid #747474;
		width: max-content;
		&__item {
			padding: 8px 16px;
			border: 0;
			background-color: transparent;
			font-size: 14px;
			&:not(:last-child) {
				margin-right: 4px;
			}
			&__selected {
				font-size: 16px;
				border-bottom: 2.5px solid #55a8fd;
				color: #55a8fd;
				&:focus {
					outline: none;
				}
			}

			&__hidden {
				display: none;
			}
		}
	}
}
