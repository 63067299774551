.verification__modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 32px;
	background-color: #fff;
	border-radius: 20px;
	z-index: 12;
	width: 1100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	&__cardInput {
		&__container {
			display: flex;
		}

		&__digit {
			width: 32px;
			height: 32px;
			text-align: center;
			border: 1px solid black;
			font-weight: 400;
			font-size: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__header {
		display: flex;
		justify-content: center;

		&__title {
			width: 100%;
			text-align: center;
			font-size: 24px;
			font-weight: 700;
		}

		// &__close {
		// 	margin-left: auto;
		// }
	}

	&__body {
		margin-top: 44px;
		display: flex;
		flex-direction: column;
		gap: 32px;
		max-height: 800px;
		overflow-y: scroll;
	}

	&__footer {
		text-align: end;
	}

	&__row {
		display: flex;
		gap: 52px;

		&__right {
			display: flex;
			justify-content: center;
			flex-direction: column;
			gap: 35px;
			// grid-template-columns: 0.8fr 1fr;
			width: 100%;
			font-size: 24px;

			&__container {
				display: flex;
				width: 100%;

				&__approvedstatus {
					font-size: 16px;
					color: green;
					padding: 8px 24px;
					border-radius: 8px;
				}

				&__rejectedstatus {
					font-size: 16px;
					color: red;
					padding: 8px 24px;
					border-radius: 8px;
				}

				&__buttons {
					display: flex;
					gap: 16px;
					margin-top: 16px;
					& > button {
						cursor: pointer;
						padding: 8px 24px;
						border-radius: 8px;
						border: 1px solid #55a8fd;
					}

					&__secondary {
						background-color: white;
						color: #55a8fd;
					}

					&__primary {
						background-color: #55a8fd;
						color: white;
						cursor: pointer;
						padding: 8px 24px;
						border-radius: 8px;
						border: 1px solid #55a8fd;
					}
				}
			}

			&__label {
				width: 250px;
			}
		}

		&__image {
			width: 300px;
			border: 1px dashed grey;
		}
	}
}

.backdrop {
	top: 0;
	left: 0;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 11;
	&__transparent {
		background-color: transparent;
		z-index: 1;
	}
}
