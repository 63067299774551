.users {
	&__modal {
		position: fixed;
		top: 50%;
		left: 50%;
		background-color: #fff;
		padding: 32px;
		z-index: 12;
		transform: translate(-50%, -50%);

		&__grid {
			display: grid;
			grid-template-columns: 120px 300px;
			row-gap: 12px;
			column-gap: 20px;
			font-size: 14px;
			align-items: center;
			margin-bottom: 20px;
			& label {
				text-align: start;
			}
			& input {
				padding: 8px 16px;
			}
		}

		&__submit {
			width: max-content;
			padding: 12px 48px;
			border-radius: 12px;
			background-color: #55a8fd;
			color: #ffffff;
			border: 0;
			margin: 0 auto;
			margin-top: 20px;
			font-size: 14px;

			&:focus {
				outline: none;
			}
		}
	}
	&__backdrop {
		top: 0;
		left: 0;
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.4);
		z-index: 11;
	}
}
