.tabs {
	&__list {
		margin-bottom: 24px;
		// border-bottom: 1px solid #747474;
		width: max-content;
		&__item {
			padding: 8px 16px;
			border: 0;
			background-color: transparent;
			font-size: 14px;
			&:not(:last-child) {
				margin-right: 4px;
			}
			&__selected {
				font-size: 16px;
				border-bottom: 2.5px solid #55a8fd;
				color: #55a8fd;
				&:focus {
					outline: none;
				}
			}
		}
	}
}

.no_break {
	& span {
		white-space: unset;
	}
}

.model {
	&__metrics_container {
		display: flex;
		gap: 16px;
		flex-wrap: wrap;
		justify-content: center;
		margin-bottom: 24px;
		&__metrics {
			display: flex;
			gap: 16px;
			&__metric {
				display: flex;
				flex-direction: column;
				gap: 8px;
				&__title {
					font-size: 14px;
					color: #747474;
				}
				&__value {
					font-size: 16px;
					color: #000000;
				}
			}
		}
	}

	&__models_container {
		display: flex;
		gap: 32px;
		flex-wrap: wrap;
		margin-bottom: 24px;

		&__model_details {
			// max-width: 500px;
			&__title {
				font-size: 16px;
				color: #000000;
				margin-bottom: 16px;
			}
		}
	}
}

.logs__container {
	height: calc(100vh - 220px);
	overflow-x: auto;

	&__header {
		position: sticky;
		top: 0;
		background-color: #fff;
	}
}
