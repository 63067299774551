.notification {
	&__modal {
		width: 430px;
		max-height: 500px;
		overflow: scroll;
		background-color: #fff;
		z-index: 12;
		position: absolute;
		left: 10px;
		top: -10px;
		transform: translate(-100%, 0%);
		box-shadow: 0px 2px 6px 2px rgba(31, 72, 126, 0.15), 0px 1px 2px 0px rgba(31, 72, 126, 0.3);
		border-radius: 12px;

		padding: 20px;
		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 20px;

			&__readbutton {
				color: #55a8fd;
				border-radius: 8px;
				font-size: 14px;
				text-decoration: underline;
				text-underline-position: under;
				cursor: pointer;
			}
		}

		&__content {
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			gap: 10px;

			&__notification {
				padding: 5px 10px;
				border-bottom: 1px solid #d9d8d8;
			}

			&__notread {
				background-color: #f8fbff;
			}
		}
	}

	&__backdrop {
		top: 0;
		left: 0;
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-color: transparent;
		z-index: 11;
	}
}
