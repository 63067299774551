.image__imageCard {
	padding: 12px;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	display: flex;
	width: max-content;
	gap: 16px;
	aspect-ratio: 392 / 190;
	max-width: 392px;
	font-size: 14px;
	font-family: 'Lato';
	position: relative;
	border-radius: 10px;

	&__var {
		border-radius: 10px;
		width: 190px;
		height: 190px;
		background-size: cover !important;
		background-repeat: no-repeat !important;
		&.image__selected {
			width: 188px;
			height: 188px;
		}
	}
}

.image__assigned {
	position: absolute;
	padding: 4px 6px;
	background-color: #b5e9c4;
	color: #48985f;
	border-radius: 4px;
	top: 8px;
	right: 12px;
	font-size: 10px;
	font-weight: 500;
	&__var {
		& > span {
			position: absolute;
			top: 0;
			transform: translateY(-50%);
			font-size: 10px;
			padding: 2px 12px;
			background-color: #b5e9c4;
			color: #48985f;
			border-radius: 4px;
		}
	}
}

.image__content {
	aspect-ratio: 121 / 166;
	& img {
		border-radius: 12px;
		object-fit: cover;
	}
}
.image__details {
	width: 100%;
	padding: 12px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	& > :first-child {
		display: flex;
		gap: 8px;
		flex-direction: column;
	}

	&__var {
		display: flex;
		align-items: flex-end;
		height: 100%;
		margin-top: auto;
		width: 100%;
		border-radius: 10px;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		& > div {
			position: relative;
			width: 100%;
			padding: 8px;
			background: rgba(255, 255, 255, 0.15);
			backdrop-filter: blur(6px);
			display: flex;
			justify-content: space-between;
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
		}
	}
}

.image__commonStyles {
	display: flex;
	gap: 8px;
	align-items: center;
}

.image__tag {
	border-radius: 4px;
	padding: 4px 6px;
	font-size: 10px;

	&:not(:last-child) {
		margin-right: 4px;
	}
	&:nth-child(1) {
		background-color: #fef4de;
		color: #f68e0d;
	}
	&:nth-child(2) {
		background-color: #fee3e5;
		color: #f05e7c;
	}
	&:nth-child(3) {
		background-color: #f2e8ff;
		color: #be84fe;
	}
}

.image__moreDetails {
	text-align: right;
	text-decoration: underline;
	color: #55a8fd;
}

.image__moreTags {
	border: 0;
	background-color: transparent;
	text-decoration: underline;
	margin-left: 6px;
	&:focus {
		outline: none;
	}
}

.image__tags {
	display: flex;
	gap: 4px;
}

.image__selected {
	border: 2px solid #55a8fd;
	background: rgba(85, 169, 253, 0.35);
	border-radius: 10px;
	box-sizing: content-box;
}

.image_list {
	width: 100%;
	&__table {
		margin-top: 24px;
		width: 100%;
		border-collapse: collapse;
		&__header {
			border: 1px solid #f5f5f5;
			border-left: 0;
			border-right: 0;
			& th {
				padding: 16px 12px;
				color: #797979;
				font-size: 14px;
				line-height: 1.17;
				font-weight: 400;
				font-family: 'Lato';
				text-transform: uppercase;
			}
		}

		&__row {
			text-align: left;
			font-size: 14px;
			&:first-child {
				border-top: 1px solid #f5f5f5;
			}

			&:nth-child(even) {
				background-color: #f5f5f5;
			}

			&__row_selected {
				background: #eff7ff !important;
			}
			& td {
				padding: 12px 0;
			}
		}
	}
}
