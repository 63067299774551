.table {
	width: 100%;
	padding: 12px;
	overflow: auto;
	border-collapse: collapse;
	&__header {
		border: 0.5px solid #cccbcb;
		border-left: 0;
		background-color: #fff;

		border-right: 0;
		&__th {
			padding: 16px 12px;
			color: #181616;
			font-size: 14px;
			line-height: 1.17;
			font-weight: 400;
			font-family: 'Lato';
			text-transform: uppercase;
			text-align: left;
			& span {
				display: block;
				width: max-content;
			}
		}
	}

	&__row {
		font-size: 14px;

		&:nth-child(even) {
			background-color: #fff;
		}
		&:nth-child(odd) {
			background-color: #f5f5f5;
		}

		&__row_selected {
			background: #eff7ff !important;
		}
		&__td {
			// width: 100px;
			padding: 12px;
			text-align: center;
			box-sizing: border-box;
			text-transform: capitalize;

			:hover {
				overflow: visible;
			}
			& span {
				display: block;
				width: max-content;
				max-width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}
