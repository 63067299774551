.datepicker {
	padding: 8px !important;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.5px;
	border: 1px solid rgba(0, 0, 0, 0.3) !important;
	border-left: 0 !important;
	border-right: 0 !important;
	border-top: 0 !important;
	border-radius: 0;
	width: 100%;
	box-sizing: border-box;
}
