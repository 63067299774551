.canvas {
	background-repeat: no-repeat;
	background-size: contain;
	margin: 0 auto;
	display: flex;
	transform-origin: 0 0;
}

.canvasContainer {
	// width: 1000px;
	// width: fit-content;
	// height: fit-content;
	width: max-content;
	height: max-content;
	position: relative;
}
.canvasSubContainer {
	overflow-y: hidden;
	overflow-x: hidden;
	position: relative;
}
.zoom__container {
	display: flex;
	gap: 15px;
	justify-content: end;
	height: 25px;
	margin-bottom: 5px;
	& > img {
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
}

.classdetails {
	// width: 200px;
	padding: 10px;
	&__class {
		display: flex;
		margin-bottom: 30px;

		&__label {
			width: 130px;
		}
	}

	&__attribute {
		background-color: #f5f5f5;
		display: flex;
		padding: 10px;
		margin-top: 10px;
		width: 240px;

		&__label {
			width: 130px;
		}
		&__value {
			background-color: #8bbdf0;
			color: white;
			width: 70px;
			padding: 5px;
			text-align: center;
		}
	}
}
