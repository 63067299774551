@import '../../../scss/imports';

.table {
	width: 100%;
	padding: 12px;
	overflow: auto;
	border-collapse: collapse;

	& th {
		position: relative;
		width: max-content;
		color: $color-title;
		&:first-child {
			border-radius: 4px 0 0 4px;
		}

		&:last-child {
			border-radius: 0 4px 4px 0;
		}
	}

	&__resizer {
		/* Displayed at the right side of column */
		position: absolute;
		top: 0;
		right: 0;
		width: 5px;
		cursor: col-resize;
		user-select: none;
	}

	&__resizer:hover,
	&__resizing {
		border-right: 2px solid blue;
	}

	&__header {
		background-color: #f8f8f8;
		border-radius: 4px;
		&__th {
			padding: 16px 12px;

			color: #181616;
			font-size: 13px;
			line-height: 1.17;
			font-weight: 400;
			font-family: 'Lato';
			text-transform: uppercase;
			text-align: left;
			// min-width: 82px;
			// box-sizing: content-box;
			& span {
				display: block;
				width: inherit;
				// min-width: 50px;
			}
		}
	}

	// &__body {
	// 	// overflow-y: auto;
	// }

	&__row {
		font-size: 14px;
		&__row_selected {
			background: #eff7ff !important;
		}
		&__td {
			background-color: inherit;
			padding: 12px;
			text-align: center;
			box-sizing: border-box;
			text-transform: capitalize;
			& span {
				display: block;
				width: 100%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}
