.root__container {
	height: calc(100vh - 80px);
	padding: 0 30px;
	margin-bottom: 0;
	overflow: auto;

	&__header {
		&__actions {
			margin-left: auto;
			justify-content: flex-end;
			align-items: center;
			display: flex;
			height: 60px;
			gap: 32px;
			position: relative;

			&__iconcontainer {
				width: 30px;
				height: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
				border-radius: 5px;
			}
		}
	}
}

.backdrop {
	top: 0;
	left: 0;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 11;
	&__transparent {
		background-color: transparent;
		z-index: 1;
	}
}

.dataset__filter {
	z-index: 13;
	position: absolute;
	width: max-content;
	right: 0;
	top: 50px;
	display: flex;
	flex-direction: column;

	&__heading {
		margin-bottom: 15px;
		color: #55a8fd;
		font-weight: 500;
		font-size: 24px;
	}

	&__grid {
		display: grid;
		grid-template-columns: 1fr 250px;
		gap: 10px;
	}
	padding: 16px;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0px 4px 8px rgba(49, 42, 42, 0.12);

	& select {
		border: 0;
		border-bottom: 1px solid #cccbcb;
		color: #cccbcb;
		padding: 8px;
	}
	& input {
		border: 0;
		border-bottom: 1px solid #cccbcb;
		color: #cccbcb;
		padding: 8px;
	}
	&__buttons {
		display: flex;
		gap: 24px;
		justify-content: flex-end;
		align-items: center;
		padding-top: 24px;

		& button {
			padding: 8px 24px;
			border: 0;
			box-shadow: 0px 4px 8px rgba(85, 168, 253, 0.2);
			border-radius: 48px;
			font-size: 12px;
			line-height: 1.12;
			font-family: 'Lato';
			color: #55a8fd;
			background-color: #fff;
			&:first-child {
				background-color: #55a8fd;
				color: #fff;
			}
		}
	}
}

.dataset {
	&__panel {
		&__container {
			height: calc(100vh - 143px);
			overflow-x: auto;
		}

		&__table__header {
			position: sticky;
			top: 0px;
			background-color: #fff;
		}
	}
}

.loader {
	position: fixed;
	bottom: 10px;
	left: 10px;
}

.dialog__modal {
	padding: 36px 48px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 20px;
	width: max-content;
	z-index: 12;

	&__note {
		color: #55a8fd;
		margin-bottom: 20px;
	}

	&__buttons {
		margin-top: 24px;
		display: flex;
		justify-content: flex-end;
		gap: 32px;
		& button {
			padding: 10px 16px;
			border: 0;
			background-color: #55a8fd;
			color: #fff;
			border-radius: 8px;
			cursor: pointer;
		}
	}
}

.actions {
	&__button {
		position: relative;
	}
	&__modal {
		position: absolute;
		list-style: none;
		background-color: #fff;
		z-index: 12;
		right: 0;
		transform: translate(-120px, -16px);
		text-align: left;
		box-shadow: 0px 1px 3px rgba(85, 79, 79, 0.25);
		border-radius: 4px;
		padding: 8px;
		& li {
			padding: 8px 16px;
			color: #55a8fd;
			text-decoration: underline;
			cursor: pointer;
			& a {
				text-decoration: none;
				color: #55a8fd;
			}
		}
	}
}

.create__job__modal {
	padding: 36px 48px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 20px;
	width: max-content;
	z-index: 12;
}

.upload__form {
	&__grid {
		min-width: 500px;
		min-height: 550px;
		display: grid;
		grid-template-columns: max-content 300px;
		row-gap: 20px;
		column-gap: 20px;
		font-size: 14px;
		align-items: center;
		margin-top: 40px;
		margin-bottom: 40px;
		& input {
			padding: 8px 16px;
		}

		& input,
		select {
			border: 0;
			border-bottom: 1px solid #aeaeae;
		}

		& select {
			padding: 8px;
		}
		&__bulletcontainer {
			display: flex;
			gap: 20px;
		}
		&__slidercontainer {
			display: flex;
			flex-direction: column;
			gap: 5px;
			& > div {
				text-align: end;
			}
			& > input {
				padding-left: 0;
				padding-right: 0;
			}
		}
		&__buttons {
			padding: 10px 16px;
			border: 0;
			background-color: #55a8fd;
			color: #fff;
			border-radius: 8px;
			cursor: pointer;
		}
		&__buttonscontainer {
			display: flex;
			justify-content: space-between;
		}
	}
}
