@import './imports';

* {
	margin: 0;
	padding: 0;
}

input:focus,
select:focus,
option:focus {
	outline: none;
}

body {
	margin: 0;
	font-family: 'Lato';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #fafafe;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* ::-webkit-scrollbar {
	width: 5.6px;
	height: 5.6px;
}
*::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
	border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
	background: #4fc3f7;
	border-radius: 10px;
}
