.message__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	gap: 16px;
	margin: auto;
	padding-top: 100px;
	padding-bottom: 100px;
	width: 100%;
	height: 100%;

	&__icon {
		width: 150px;
	}

	&__title {
		font-size: 48px;
		font-weight: 400;
		color: #55a8fd;

		&__success {
			color: #48985f;
		}
	}

	&__description {
		font-size: 24px;
	}
}
