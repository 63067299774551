.common_styles {
	&__input {
		display: flex;
		background-color: transparent;
		border-bottom: 1px solid #cccbcb;
		padding-right: 4px;
		align-items: center;
		& input {
			background-color: transparent;
			width: 100%;
			box-sizing: border-box;
			border: 0 !important;
			color: #000;
			padding: 4px 6px;
			text-transform: capitalize;
			&:focus {
				outline: none;
			}
		}
		& img {
			width: 12px;
			height: 12px;
		}
	}

	&__drop_down {
		position: absolute;
		background-color: #fff;
		box-shadow: 0px 4px 8px rgba(49, 42, 42, 0.12);
		list-style: none;
		bottom: 0;
		width: 100%;
		box-sizing: border-box;
		max-height: 200px;
		overflow: auto;
		z-index: 2;
		transform: translate(0, 100%);
		& li {
			text-transform: capitalize;
			padding: 8px 12px;
			text-align: left;
		}
		&__selected {
			background-color: #f2f2f2;
		}
	}

	&__backdrop {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}
}
