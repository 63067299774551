.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 11;
}

.modal {
	position: fixed;
	top: 45%;
	left: 50%;
	width: max-content;
	transform: translate(-50%, -50%);
	z-index: 12;
	padding: 32px;

	&__container {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		padding: 24px;
		border-radius: 5px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-bottom: 24px;

		&__title {
			font-size: 18px;
			font-weight: 500;
		}
	}

	&__form {
		display: flex;
		flex-direction: column;
		padding: 24px;
		width: 250px;

		&__submit {
			margin-top: 24px;
			width: max-content;
			padding: 12px 48px;
			border-radius: 12px;
			background-color: #55a8fd;
			color: #ffffff;
			border: 0;
			font-size: 14px;

			&:focus {
				outline: none;
			}
		}
	}
}

.model {
	&__panel {
		&__container {
			height: calc(100vh - 180px);
			overflow-x: auto;
			overflow: auto;
		}

		&__header {
			position: sticky;
			top: 0px;
			background-color: #fff;

			&__arrow {
				height: 12px;
				margin-left: 5px;
			}
		}
	}

	&__header {
		display: flex;
		margin-bottom: 20px;
		justify-content: space-between;
		margin-right: 20px;
		position: sticky;
		top: 0;
		background-color: #fff;
		z-index: 5;

		&__input {
			display: flex;
			gap: 24px;
			margin-right: 20px;
		}
	}
}
