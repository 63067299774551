.rootContainer {
	background-color: #fff;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	position: sticky;
	top: 0;
	z-index: 11;
}

.hideNavbar {
	display: none;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 90%;
	margin: 0 auto;
	padding: 12px 0;
	max-width: 1440px;
}

.logo {
	height: 40px;
	flex-shrink: 0;
}

.navigation {
	display: flex;
	gap: 24px;
	li {
		list-style: none;
		font-size: 18px;
		font-family: 'Lato';
		line-height: 1.17;
		&:hover {
			opacity: 0.5;
			cursor: pointer;
		}
		& a {
			text-decoration: none;
		}
	}
	margin-left: 32px;
}

.userInfo {
	display: flex;
	margin-left: auto;
	align-items: center;
	gap: 24px;
	position: relative;

	&__avatarimage {
		border-radius: 50%;
	}
}

.notification__backdrop {
	top: 0;
	left: 0;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 11;
}

.user {
	&__backdrop {
		background-color: rgba(0, 0, 0, 0.65);
	}

	&__modal {
		position: fixed;
		top: 50%;
		left: 50%;
		width: max-content;
		background-color: #fff;
		transform: translate(-50%, -50%);
		padding: 24px 32px;
		z-index: 2;
		border-radius: 8px;
		&__close_button {
			display: flex;
			justify-content: flex-end;
			& img {
				width: 16px;
				height: 16px;
			}
		}
	}

	&__invite {
		display: grid;
		grid-template-columns: 100px 500px;
		gap: 24px;
		margin-top: 32px;

		& input,
		select {
			border: 0;
			padding: 4px 8px;
			border-bottom: 1px solid #ababab;
			width: 100%;
		}
	}

	&__emails {
		display: flex;
		gap: 8px;
		&__chips {
			display: flex;
			max-width: 500px;
			flex-wrap: wrap;
			align-items: center;
			gap: 10px;
		}
		&__chip {
			padding: 4px 12px;
			background-color: #eee;
			border-radius: 20px;
			max-width: 150px;
			display: flex;
			& span {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	&__button {
		background-color: #55a8fd;
		color: #fff;
		padding: 12px 32px;
		border-radius: 12px;
		border: 0;
		margin-top: 32px;
		display: block;
		margin-left: auto;

		&:disabled {
			background-color: #dfdfdf;
		}
	}
}

.notifications {
	width: 32px;
	height: 32px;
	box-shadow: 0px 4px 8px rgba(85, 168, 253, 0.2);
	padding: 10px;
	border-radius: 12px;
}

.userContainer {
	display: flex;
	align-items: center;
	gap: 16px;
	box-shadow: 0px 4px 8px rgba(85, 168, 253, 0.2);
	padding: 8px;
	border-radius: 12px;
	position: relative;
}

.userDetails {
	display: inline-flex;
	flex-direction: column;
	p {
		margin: 0;
		font-size: 14px;
		line-height: 1.17;
	}

	p:last-child {
		color: #55a8fd;
		font-size: 10px;
	}
}

.userAvatar {
	width: 38px;
	height: 38px;
	img {
		border-radius: 50%;
	}
}

.backdrop {
	position: fixed;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1;
}

.modal {
	position: absolute;
	background-color: #fff;
	bottom: 0;
	right: 0;
	transform: translateY(105%);
	box-shadow: 0px 4px 8px rgba(82, 82, 82, 0.2);
	z-index: 2;
	border-radius: 8px;

	& div {
		padding: 12px 16px;
		cursor: pointer;
	}

	& a {
		text-decoration: none;
		color: #000;
	}

	& :not(:last-child) {
		border-bottom: 1px solid rgb(0, 0, 0);
	}
}

.bell__icon {
	position: relative;
}
.notification {
	position: relative;

	&__count {
		position: absolute;
		top: -5px;
		right: -5px;
		background-color: red;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		color: white;
		text-align: center;
	}
}
.notification__count {
	position: absolute;
	top: -5px;
	right: -5px;
	background-color: red;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	color: white;
	text-align: center;
}

.notification__container {
	position: absolute;
	top: 40px;
	left: 0px;
}
