.container {
	height: calc(100vh - 96px);
	overflow: hidden;
	padding: 0;
	padding-top: 30px;
}

.users {
	&__heading {
		text-align: start;
		display: block;
		font-size: 36px;
		font-weight: 500;
	}
	&__rowone__container {
		display: flex;
		gap: 100px;
		margin-bottom: 32px;
	}
	&__header {
		display: flex;
		flex-direction: column;
		gap: 32px;
		border-radius: 24px;
		width: max-content;
		padding-left: 48px;
		&__heading {
			font-size: 24px;
			font-weight: 500;
		}
		&__details {
			display: flex;
			gap: 32px;
		}
		&__avatar {
			width: 120px;
			height: 120px;
			position: relative;
			& > input {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				display: none;
			}
			&:hover {
				& > img {
					opacity: 0.2;
				}
				& > input {
					display: block;
				}
			}
		}

		&__user_details {
			display: flex;
			flex-direction: column;
			gap: 12px;

			&__editable {
				border: 1px solid black;
				background-color: #55a8fd;
			}

			&__input {
				display: flex;
				gap: 56px;

				& label {
					width: 150px;
					text-align: end;
				}

				& input {
					border: none;
					padding: 4px;
					border-radius: 4px;
					width: 300px;
					background-color: white;
					color: black;
				}
			}

			&__password {
				display: flex;
				gap: 24px;
				> p {
					color: #55a8fd;
					text-decoration: underline;
					text-underline-position: under;
					font-size: 12px;
					cursor: pointer;
				}
			}
		}
	}

	&__contract {
		padding-left: 48px;
		border-radius: 24px;
		display: flex;
		flex-direction: column;
		gap: 32px;

		&__container {
			display: flex;
			flex-direction: column;
			gap: 30px;
		}

		&__details {
			display: flex;
			flex-direction: column;
			gap: 5px;
			&__heading {
				font-size: 16px;
			}
			&__button {
				padding: 8px;
				border-radius: 5px;
				font-size: 12px;
				border: none;
			}
			&__buttoncontainer {
				display: flex;
				gap: 20px;
				justify-content: space-between;
				& div {
					display: flex;
					gap: 24px;
				}
			}
		}
	}

	&__billing {
		padding-left: 48px;
		border-radius: 24px;
		display: flex;
		flex-direction: column;
		gap: 32px;
		max-width: 100%;

		&__gallery {
			display: flex;
			gap: 32px;
			max-width: 90%;
			margin-bottom: 50px;
			overflow-x: scroll;

			&__item {
				display: flex;
				flex-direction: column;
				gap: 24px;
				justify-content: center;
				align-items: center;

				&__image {
					width: 200px;
					height: 200px;
				}

				&__title {
					font-size: 16px;
				}
			}
		}

		&__container {
			display: flex;
			gap: 100px;
			& > span {
				width: 150px;
			}
		}
		&__input {
			display: flex;
			gap: 15px;
			margin-left: 56px;

			&__actions {
				display: flex;
				gap: 24px;
				margin-left: 12px;
			}

			& > label {
				width: 180px;
				text-align: end;
			}
			& > input {
				border: 1px solid #ababab;
				padding: 4px;
				border-radius: 4px;
				width: 300px;
			}
		}
	}

	&__password_update_modal {
		position: fixed;
		top: 50%;
		left: 50%;
		z-index: 13;
		transform: translate(-50%, -50%);
		background-color: #fff;
		padding: 40px 48px;

		&__heading {
			text-align: center;
			font-size: 18px;
			line-height: 1.2;
		}

		&__form {
			display: grid;
			grid-template-columns: 1fr 200px;
			gap: 16px;
			margin-top: 16px;
			& input {
				border: 0;
				border-bottom: 0.5px solid #000;
			}
			&__submit {
				width: max-content;
				padding: 12px 48px;
				border-radius: 12px;
				background-color: #55a8fd;
				color: #ffffff;
				border: 0;
				margin: 0 auto;
				font-size: 14px;
				&:focus {
					outline: none;
				}
			}
		}
	}
}

.dashboard {
	display: flex;
	height: 100%;
	padding: 20px;
	overflow: scroll;
	&__leftpanel {
		width: 250px;
		// margin-left: 50px;
		margin-right: 50px;

		&__profile {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			&__avatar {
				width: 200px;
				height: 200px;
				position: relative;
				// & > img {
				// 	border-radius: 50%;
				// }
				& > input {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					display: none;
				}
				&:hover {
					& > img {
						opacity: 0.2;
					}
					& > input {
						display: block;
					}
				}
			}

			&__name {
				margin-top: 24px;
			}

			&__role {
				margin-top: 6px;
				color: #55a8fd;
			}
		}

		&__actions {
			margin-left: 64px;
			margin-top: 70px;
			@media screen and (max-width: 1400px) {
				margin-top: 32px;
			}
			&__active {
				color: #55a8fd;
				text-decoration: underline;
			}
			> ul {
				margin-top: 24px;
				display: flex;
				flex-direction: column;
				gap: 24px;

				& > li {
					list-style: none;
					font-size: 16px;
					cursor: pointer;
					&:hover {
						color: #55a8fd;
					}
				}
			}
		}
	}
	&__rightpanel {
		width: 75%;
		border-left: 1px solid black;
		max-height: 80vh;
		&__agreement {
			padding: 25px;
			overflow-y: scroll;
			margin-top: 24px;
			max-height: 600px;

			@media screen and (max-width: 1700px) {
				max-height: 500px;
			}
			@media screen and (max-width: 1300px) {
				max-height: 400px;
			}
		}

		&__billing {
			padding: 25px;
			&__detailedview {
				margin-left: 32px;
			}

			&__table {
				overflow-y: scroll;
				margin-top: 24px;
				max-height: 600px;

				@media screen and (max-width: 1700px) {
					max-height: 500px;
				}
				@media screen and (max-width: 1300px) {
					max-height: 400px;
				}
			}
		}
	}
}

.button {
	padding: 10px 16px;
	border: 0;
	background-color: #55a8fd;
	color: #fff;
	border-radius: 8px;
	max-width: 150px;
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 12;
}
