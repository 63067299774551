.rootContainer {
	height: calc(100vh - 84px);
	padding: 0 30px;
	margin-bottom: 0;
	overflow: auto;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 20px 10px 20px 10px;
	min-height: calc(100vh - 200px);
}
.tabs {
	&__list {
		padding-top: 20px;
		margin-bottom: 18px;
		width: max-content;
		display: flex;
		align-items: center;
		&__item {
			padding: 8px 16px;
			border: 0;
			background-color: transparent;
			font-size: 14px;
			&:not(:last-child) {
				margin-right: 4px;
			}
			&__selected {
				font-size: 16px;
				border-bottom: 2.5px solid #55a8fd;
				color: #55a8fd;
				&:focus {
					outline: none;
				}
			}
		}
	}
}

.session {
	&__tabs {
		&__container {
			padding-top: 6px;
			padding-bottom: 6px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: #fff;
			position: sticky;
			top: 0;
			z-index: 2;
			&__right {
				position: relative;
				margin-left: auto;
			}
			&__icons {
				padding: 6px;
				background: #ffffff;
				box-shadow: 0px 4px 8px rgba(49, 42, 42, 0.12);
				border-radius: 5px;
			}
		}
	}

	&__panel {
		&__container {
			height: calc(100vh - 143px);
			overflow-x: auto;
		}

		&__table_header {
			position: sticky;
			top: 0;

			&__split {
				&__heading {
					text-align: center;
				}
				&__content {
					margin-left: 30px;
					display: flex;
					gap: 20px;
					flex: 1;
					> p {
						width: 70px;
						text-align: center;
					}
				}
			}
		}
	}
}

.hide {
	display: none;
}
.container {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.mainHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.backdrop {
	top: 0;
	left: 0;
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 11;
}

.sessions__filter {
	z-index: 12;
	position: absolute;
	width: max-content;
	right: 0;
	top: 30px;
	display: flex;
	flex-direction: column;

	&__heading {
		margin-bottom: 15px;
		color: #55a8fd;
		font-weight: 500;
		font-size: 24px;
	}

	&__grid {
		display: grid;
		grid-template-columns: 1fr 250px;
		gap: 10px;
	}
	padding: 16px;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0px 4px 8px rgba(49, 42, 42, 0.12);

	& select {
		border: 0;
		border-bottom: 1px solid #cccbcb;
		color: #cccbcb;
		padding: 8px;
	}
	& input {
		border: 0;
		border-bottom: 1px solid #cccbcb;
		color: #cccbcb;
		padding: 8px;
	}
	&__buttons {
		display: flex;
		gap: 24px;
		justify-content: space-around;
		align-items: center;
		padding-top: 24px;

		& button {
			padding: 8px 24px;
			border: 0;
			box-shadow: 0px 4px 8px rgba(85, 168, 253, 0.2);
			border-radius: 48px;
			font-size: 12px;
			line-height: 1.12;
			font-family: 'Lato';
			color: #55a8fd;
			background-color: #fff;
			&:first-child {
				background-color: #55a8fd;
				color: #fff;
			}
		}
	}
}
