.searchbar {
	display: inline-flex;
	align-items: center;
	padding: 8px 16px;
	border-radius: 6px;
	width: 300px;
	gap: 8px;
	border: 1px solid rgba(0, 0, 0, 0.12);
	&__filtericon {
		width: 15px;
		height: 15px;
	}
	& input {
		border: 0;
		width: 100%;
		font-size: 14px;
		line-height: 1.12;
		&:focus {
			outline: none;
		}
	}
}
