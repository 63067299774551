.annotation {
	border-radius: 4px;
	border: 2px solid white;
	background-color: white;

	&__accordion {
		display: inline-flex;
		align-items: center;
		padding: 8px;
		background-color: white;
		border-radius: 6px;
		gap: 4px;
		&:not(:first-child) {
			margin-top: 12px;
		}
		width: 100%;
		box-sizing: border-box;

		&__container {
			background-color: white;
		}

		&__attributeheader {
			display: flex;
			background-color: #f5f5f5;
			// justify-content: space-between;
			// padding: 8px 0px;

			&__buttons {
				display: flex;
				gap: 8px;
			}
		}

		&__class {
			display: flex;
			align-items: center;
			margin-right: 24px;
			padding: 6px 0px 8px 6px;
			gap: 24px;
			flex: 1;
			background-color: #f5f5f5;

			&__dropdown {
				display: flex;
				flex: 1;
			}
		}

		&_selected {
			border: 1px solid #55a8fd;
		}

		&__button {
			background-color: transparent;
			width: 100%;
			padding: 16px;
			border-radius: 6px;
			display: flex;
			align-items: center;
			gap: 20px;
			&__approved {
				border-color: #afd7ba;
			}
			&__rejected {
				border-color: #fee3e5;
			}
			&__corrected {
				border-color: blue;
			}
			&__color_picker {
				width: 12px;
				height: 12px;
				border-radius: 50%;
				position: relative;
				flex-shrink: 0;
			}
			&__icons {
				display: inline-flex;
				gap: 8px;
				margin-left: auto;
				margin-right: 2px;
				align-items: center;

				& img {
					width: 16px;
					height: 16px;
					cursor: pointer;
				}
			}
			&__value {
				background-color: #ebeaea;
				width: 20px;
				height: 20px;
				padding: 1px;
				border-radius: 50%;
				font-size: 12px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		&__panel {
			padding: 0px 24px 0px 24px;
			background-color: #fff;

			&__label {
				display: flex;
				gap: 8px;
				margin-bottom: 12px;
				& > input {
					padding: 0 2px;
					border: 0;
					border-bottom: 1px solid #000;
					&:focus {
						outline: none;
					}
					background-color: transparent;
				}
			}

			&__attributes {
				display: flex;
				padding: 8px;
				// margin-top: 12px;
				flex-direction: column;
				gap: 8px;
				background-color: #f5f5f5;
				margin-bottom: 12px;
				border-radius: 4px;
				border-left: 3px solid #b2afaf;

				&__approved {
					border-left: 3px solid #48985f;
				}

				&__rejected {
					border-left: 3px solid #f05e7c;
				}

				&__corrected {
					border-left: 3px solid #55a8fd;
				}

				&__label {
					width: max-content;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					flex-shrink: 0;
					font-size: 14px;
					font-weight: 500;
					text-transform: capitalize;
				}

				&__possible_values {
					display: flex;
					gap: 8px;
					flex-wrap: wrap;
					&__span {
						padding: 4px 6px;
						box-shadow: 0px 1px 5px rgba(123, 127, 132, 0.12);
						cursor: pointer;
						background-color: #fff;
						// border-radius: 6px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						display: inline;
						max-width: 100px;
						font-size: 12px;
						&_selected {
							background-color: #badcff;
						}

						&_disabled {
							background-color: #e3dfdf;
						}
					}
				}

				&__buttons {
					display: flex;
					gap: 16px;
					margin-top: 16px;
					padding-bottom: 12px;
					& > button {
						padding: 4px 8px;
						border-radius: 6px;
						box-shadow: 0px 1px 5px rgba(123, 127, 132, 0.12);
						background-color: #55a8fd;
						color: #fff;
						&:disabled {
							background-color: #d8d8d8;
						}
					}
				}
			}
		}
	}
}

.color__pallet {
	position: absolute;
	z-index: 2;
	transform: translate(-12px, 16px);
	left: 0;
}

.backdrop {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
}
