.app {
	height: 100vh;
	display: flex;
	flex-direction: column;

	&__global {
		height: 100vh;
	}

	&__content {
		height: 100%;
	}
}
.scrollButton {
	position: fixed;
	top: 200;
	width: 100;
	height: 20;
}
.hideScrollButton {
	display: none;
}
.showScrollButton {
	display: 'block';
}
