.login {
	&__container {
		background: url('/assets/loginContainer/BannerImage.png');
		background-size: 100% 100%;
		height: 100vh;
		width: 100vw;
		background-repeat: no-repeat;
		background-position: center;
		& > div {
			display: flex;
			position: absolute;
			top: 45%;
			left: 50%;
			transform: translate(-50%, -50%);
			align-items: center;
			gap: 32px;
		}
	}

	&__resetsuccess {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto;
		width: 500px;

		&__tick {
			width: 150px;
			height: 150px;
		}

		&__title {
			font-size: 48px;
			font-weight: 400;
			color: #00d43b;
			margin-top: 16px;
		}

		&__description {
			font-size: 24px;
			font-weight: 400;
			color: #000;
			margin-top: 16px;
			text-align: center;
		}

		&__button {
			margin-top: 32px;
			padding: 10px 16px;
			border: 0;
			background-color: #55a8fd;
			color: #fff;
			border-radius: 8px;
			font-size: 16px;
		}
	}

	&__logo {
		width: 240px;
	}

	&__form {
		&_container {
			padding: 16px 0;
			padding-left: 32px;
			border-left: 1px solid rgba(0, 0, 0, 0.5);
			max-width: 500px;

			&__header {
				margin-bottom: 50px;
			}
			& h2 {
				color: #55a8fd;
				font-size: 36px;
				font-family: 'Roboto', sans-serif;
				line-height: 1.5;
				font-weight: 600;
			}

			& p {
				color: rgba(0, 0, 0, 0.5);
				font-weight: 500;
				font-size: 16px;
			}
			&__forgotpassword {
				background-color: #dcffe4;
				color: #0b8e30;
				padding: 10px;
				display: flex;
				gap: 10px;
				&__tick {
					width: 30px;
					height: 30px;
				}

				&__message {
					margin: auto;
					max-width: 300px;
					font-size: 16px;
				}
			}
		}

		display: flex;
		flex-direction: column;
		margin-top: 16px;
		&__input {
			width: 100%;
			border: 0;
			background-color: transparent;
			border-bottom: 1px solid #000;
			padding: 12px 16px;
			font-size: 16px;
		}

		&__password {
			display: flex;
			align-items: center;

			width: 100%;
			& input {
				border: 0;
				background-color: transparent;
				padding: 12px 16px;
				font-size: 16px;
			}
			border-bottom: 1px solid #000;
			&__show {
				margin-left: auto;
				width: 32px;
				height: 16px;
			}
		}

		&__agreement {
			display: flex;
			align-items: center;
			gap: 10px;
			margin-top: 75px;
			& input {
				width: 20px;
				height: 20px;
			}

			&__links {
				text-decoration: underline;
				text-underline-position: under;
				color: #55a8fd;
			}
		}

		&__forgotpassword {
			margin-top: 16px;
			margin-left: 16px;
			color: #55a8fd;
			font-size: 14px;
			cursor: pointer;
		}

		&__buttons {
			margin-top: 24px;
			margin-top: 64px;
			display: flex;
			justify-content: start;
			gap: 16px;
			& button {
				padding: 10px 16px;
				border: 0;
				background-color: #55a8fd;
				color: #fff;
				border-radius: 8px;
				font-size: 16px;
				cursor: pointer;
			}
		}
	}
}

.signup {
	display: flex;
	flex-direction: column;
	gap: 24px;

	&__aggrement {
		margin-top: 48px;
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	&__partition {
		position: relative;
		// border: 1px solid black;
		&__OR {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -60%);
			background-color: white;
			padding: 5px 10px;
			color: #949494;
		}
	}

	&__links {
		color: #55a8fd;
		text-decoration: underline;
		cursor: pointer;

		&__checkbox {
			margin-right: 5px;
		}
	}
}
