.org {
	&__raise_button {
		position: fixed;
		bottom: 16px;
		right: 16px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		& > button {
			background-color: transparent;
			border: 0;
			& > img {
				border-radius: 50%;
				box-shadow: 0px 5px 13px rgba(85, 169, 253, 0.7);
			}
		}
	}
}

.modal {
	padding: 36px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border-radius: 20px;
	width: max-content;
	z-index: 12;

	&__close_button {
		display: flex;
		justify-content: flex-end;
		& img {
			width: 16px;
			height: 16px;
		}
	}

	&__buttons {
		margin-top: 24px;
		display: flex;
		justify-content: flex-end;
		gap: 16px;
		width: 100%;
		& button {
			padding: 10px 16px;
			border: 0;
			background-color: #55a8fd;
			color: #fff;
			border-radius: 8px;
		}
	}
}

.backdrop {
	top: 0;
	left: 0;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 11;
}

.upload__form {
	&__grid {
		display: grid;
		grid-template-columns: 150px 300px;
		row-gap: 12px;
		column-gap: 20px;
		font-size: 14px;
		align-items: center;
		& input {
			padding: 8px 16px;
		}

		& input,
		select {
			border: 0;
			border-bottom: 1px solid #aeaeae;
		}

		& select {
			padding: 8px;
		}
	}
}
