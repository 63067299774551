.class {
	&__raise_button {
		position: fixed;
		bottom: 16px;
		right: 16px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		& > button {
			background-color: transparent;
			border: 0;
			& > img {
				border-radius: 50%;
				box-shadow: 0px 5px 13px rgba(85, 169, 253, 0.7);
			}
		}
	}
	&__attributes {
		&__tags {
			display: flex;
			width: 600px;
			flex-wrap: wrap;
			gap: 8px;
			&__item {
				display: flex;
				align-items: center;
				gap: 8px;
				padding: 4px 8px;
				font-size: 10px;
				background-color: #d9d9d9;
				border-radius: 4px;
				width: max-content;
				text-transform: capitalize;
				color: #292929;
			}
		}
		&__selected {
			&__button {
				background-color: #55a8fd;
				color: #fff;
				border-radius: 6px;
				padding: 4px 12px;
				margin-left: 8px;
				border: 0;
				&:focus {
					outline: none;
				}
			}
		}
		&__search {
			width: 300px;
			padding: 8px 16px;
		}
	}
}

.actions__container {
	position: relative;
	width: max-content;
	margin-left: auto;
	display: flex;
	margin-bottom: 20px;
	gap: 32px;

	&__iconcontainer {
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
	}
}
