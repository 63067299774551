.model__header {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 10px;
	position: sticky;
	top: 0;
	background-color: #fff;
	z-index: 5;
}

.job {
	&__panel {
		&__container {
			height: calc(100vh - 180px);
			overflow-x: auto;
			overflow: auto;
		}

		&__header {
			position: sticky;
			top: 0px;
			background-color: #fff;

			&__arrow {
				height: 12px;
				margin-left: 5px;
			}
		}
	}
	&__header {
		display: flex;
		margin-bottom: 20px;
		justify-content: space-between;
		margin-right: 20px;
		position: sticky;
		top: 0;
		background-color: #fff;
		z-index: 5;

		&__input {
			display: flex;
			gap: 24px;
			margin-right: 20px;
		}
	}
}
