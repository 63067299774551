.container {
	display: flex;
	align-items: center;
	justify-content: center;
	&__button {
		display: flex;
		width: 156px;
		padding: 10px 24px;
		justify-content: center;
		align-items: center;
		gap: 12px;
		border-radius: 12px;
		background: #55a8fd;
		border: none;
		color: white;
		cursor: pointer;
	}

	&__action__container {
		display: flex;
		justify-content: end;
		gap: 20px;
		margin-top: 10px;
	}
}
.form {
	&__container {
		text-align: center;
		display: flex;
		flex-direction: column;
		width: max-content;
		margin: 0 auto;
		box-sizing: border-box;
		padding: 48px;
		padding-bottom: 20px;
		height: calc(100vh - 140px);
		width: 1000px;

		&__heading {
			color: #55a8fd;
		}

		&__body {
			width: 1000px;
			height: 500px;
			overflow-y: scroll;
		}
	}

	&__steps {
		width: 900px;
		margin-top: 48px;
		margin-bottom: 100px;
		display: flex;
		color: #a5a5a5;
		font-size: 16px;

		&__heading {
			position: absolute;
			bottom: -30px;
			width: max-content;
			&__selected {
				color: #55a8fd;
			}
		}

		&__step {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 18px;
		}

		&__number {
			border-radius: 8px;
			background: #ededed;
			width: 48px;
			height: 48px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;

			&__selected {
				background-color: #55a8fd;
				color: white;
			}
		}

		& > hr {
			flex: 1;
			height: 2px;
			border: none;
			background-color: #ededed;
			stroke-width: 1px;
			margin-top: 24px;
		}
	}
}

.asterisk {
	color: red;
}

.photo {
	width: 150px;
	height: max-content;
	position: relative;

	&__container {
		&:hover {
			& > div {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: auto;
				color: white;
				cursor: pointer;
			}
		}
	}

	&__changecontainer {
		position: absolute;
		background-color: rgba(0, 0, 0, 0.5);
		width: 100%;
		height: 100%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: none;
		gap: 10px;
	}

	&__uploadcontainer {
		width: 200px;
		height: 200px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		border: 2px dashed #a5a5a5;
		background: #f5f5f5;
		cursor: pointer;
	}

	&__input {
		display: none;
	}
}

.personal {
	display: flex;
	gap: 34px;

	&__photo {
		width: 300px;
		height: max-content;
		position: relative;
	}

	&__inputcontainer {
		display: flex;
		flex-direction: column;
		gap: 32px;
	}

	&__label {
		width: 130px;
		text-align: start;
	}

	&__input {
		border: none;
		border-bottom: 1px solid #cccbcb;
	}
	&__address {
		width: 360px;
		height: 70px;
		border: 1px solid #cccbcb;
		text-align: start;
		padding: 20px;
		line-height: 16px;
	}

	&__inputrow {
		display: flex;
		gap: 60px;
	}
}

.agreement {
	font-size: 20px;
	color: #727272;
	// height: 360px;
	overflow-y: scroll;

	&__html {
		text-align: start;
		margin-bottom: 20px;
	}

	&__container {
		margin-top: 30px;
		line-height: 1.8;
		text-align: start;
	}
	&__input {
		border: none;
		border: 0.5px solid #727272;
		margin-left: 10px;
		margin-right: 10px;
		padding: 2px 5px;
		width: 300px;
		font-size: 20px;
		color: #727272;

		&__name {
			width: 264px;
		}

		&__age {
			width: 140px;
		}

		&__address {
			width: 570px;
		}
	}

	&__checkbox__container {
		display: flex;
		gap: 10px;
		margin-top: 10px;

		> input {
			color: #55a8fd;
		}
	}
}

.finalagreement {
	width: 100%;
	font-size: 14px;
	text-align: start;

	&__container {
		margin-top: 30px;
		line-height: 1.3;
		text-align: start;
	}
}

.payment {
	height: 100%;
	overflow-y: scroll;

	&__photo {
		width: 200px;
		&__uploadcontainer {
			width: 180px;
			height: 150px;
			display: flex;
			flex-direction: column;
			gap: 10px;
			justify-content: center;
			align-items: center;
			border-radius: 6px;
			border: 2px dashed #a5a5a5;
			background: #f5f5f5;
			padding: 10px;
		}
	}

	&__heading {
		margin-top: 48px;
		margin-bottom: 32px;
		text-align: start;
		color: #55a8fd;
		font-size: 28px;
	}

	&__cardInputDigit {
		width: 32px;
		height: 32px;
		text-align: center;
	}

	&__inputrows {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	&__inputrow {
		display: flex;

		&__input {
			border: none;
			border-bottom: 1px solid #cccbcb;
			width: 300px;
			padding: 5px;
			height: max-content;
		}

		&__note {
			font-size: 16px;
			color: #f86464;
			max-width: 230px;
		}

		&__container {
			display: flex;
			flex-direction: column;
			text-align: start;
		}

		&__label {
			text-align: start;
			width: 300px;
		}
	}

	&__actions {
		display: flex;
		gap: 24px;
		& > button {
			display: flex;
			width: 140px;
			padding: 10px 36px;
			justify-content: center;
			align-items: center;
			gap: 12px;
			border-radius: 12px;
		}

		&__OTP {
			background: #55a8fd;
			border: none;
			color: white;
		}

		&__resend {
			background-color: white;
			color: #55a8fd;
			border: 1px solid #55a8fd;
		}
	}
}
