.container {
	display: flex;
	gap: 100px;
}

.actions {
	margin-bottom: 20px;

	& button {
		font-weight: 500;
		background-color: #55a8fd;
		color: white;
		padding: 5px 10px;
		border: 2px solid #55a8fd;
		border-radius: 5px;
		cursor: pointer;
		&:focus {
			outline: none;
		}
	}
}
